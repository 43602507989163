<template>
  <!-- 个人中心 -->
  <div class="personalcenter">
    <div class="contain">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "Personalcenter",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.personalcenter {
  background: #f7f7f7;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .contain {
    width: 1760px;
    margin: 0 auto;
    overflow: auto;
  }
}
</style>
